import { Text2XL, Text5XL, TextXL } from '../GeneralComponents';
import Image from 'next/image';
import locale from '../../locales/en/NativeLanding';

const Examples = () => {
  return (
    <div className="bg-grey-50">
      <div className="mx-auto flex max-w-7xl flex-col overflow-hidden px-3 py-16 text-center md:px-0">
        <Text5XL className="mb-4">{locale.landing.examples.header}</Text5XL>
        <Text2XL className="color-grey-700 mb-16">
          {locale.landing.examples.subheader}
        </Text2XL>

        <div className="no-scrollbar mx-2 flex flex-row overflow-scroll min-[1200px]:mx-auto min-[1200px]:overflow-visible">
          {locale.landing.examples.examples.map((video, index) => (
            <div key={index} className="mr-8 last:mr-0">
              <video
                className="mx-auto mb-8 min-w-[266px]  rounded-xl"
                height={473}
                width={266}
                controls
                poster={video.image}
                preload="none"
              >
                <source src={video.video} />
                Your browser does not support the video tag.
              </video>
              <Text2XL className="mt-8 text-center">{video.header}</Text2XL>
              <TextXL className="m5-2 mb-8 text-center text-grey-500 min-[1200px]:mb-0">
                {video.subheader}
              </TextXL>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Examples;
