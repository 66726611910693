const ArcadeDemo = (props: { className?: string }) => (
  <div
    className={`relative w-full pb-[calc(54.863945578231295%+41px)] ${props.className} lg:pb-0`}
  >
    <iframe
      src="https://demo.arcade.software/8iE75YkNnI1vLeXMh5pu?embed&show_copy_link=true"
      title="Jupitrr AI - Create B-roll 10X Faster for Your Videos"
      className="absolute left-0 top-0 h-full w-full lg:relative lg:left-auto lg:top-auto"
      frameBorder="0"
      loading="lazy"
      allowFullScreen
      allow="clipboard-write"
    ></iframe>
  </div>
);

export default ArcadeDemo;
