import { Badge, Text4XL, Text5XL, TextXL } from '../GeneralComponents';
import { SectionContent } from '../OldGeneralComponents';
import Image from 'next/image';
import VideoGif from './VideoGif';
import locale from '../../locales/en/NativeLanding';
import parse from 'html-react-parser';
const MoreFeatures = () => {
  return (
    <SectionContent className="py-24" maxWidth={'1024px'}>
      <Text5XL className="mb-16 text-center">
        {locale.landing.more_features_header}
      </Text5XL>
      <div className="mx-auto grid w-full justify-center gap-8 md:mx-auto lg:grid-cols-2">
        {locale.landing.more_features.map((tutorial) => {
          return (
            <div
              key={tutorial.header}
              className="flex h-auto flex-col items-center rounded-lg border-2 border-solid border-gray-200 p-6  md:p-8"
            >
              <div className="relative mb-8 flex flex-col">
                {!tutorial?.isReady ? (
                  <div className="absolute -top-[40px] md:-top-[49px]">
                    <Badge
                      text={'Coming Soon'}
                      colorType="light-blue"
                      className="!w-[100px] !text-xs"
                    />
                  </div>
                ) : null}
                <Text4XL className="my-4">{parse(tutorial.header)}</Text4XL>
                <TextXL className="min-h-[75px] !font-400 text-grey-500 ">
                  {parse(tutorial.subheader)}
                </TextXL>
              </div>
              <div className=" w-full">
                <Image
                  src={tutorial.image_url}
                  alt={tutorial.header}
                  width={390}
                  height={232}
                  className="rounded-2xl bg-grey-50"
                />
              </div>
            </div>
          );
        })}
      </div>
    </SectionContent>
  );
};

export default MoreFeatures;
