import { DEMO_VIDEO_URL } from '@/models/constant';
import { FooterConversionCard } from '../components/AudiogramHome/Greetings';
import { LocalStorageItems, getItem } from '../utils/generalUtilities';
import { ModalProvider } from '../hooks/useModal';
import { NextSeo } from 'next-seo';
import { SectionContent } from '../components/OldGeneralComponents';
import ArcadeDemo from '../components/Shared/ArcadeDemo';
import Examples from '@/components/AiVideoHome/Examples';
import FeaturesDetails from '../components/AudiogramHome/FeaturesDetails';
import FeaturesList from '@/components/AiVideoHome/FeaturesList';
import Footer from '../components/Footer';
import HeroBanner from '../components/AiVideoHome/Banner';
import Image from 'next/image';
import JPHeader from '../components/Shared/JPHeader';
import Logos from '../components/AudiogramHome/Logos';
import MoreFeatures from '../components/AudiogramHome/MoreFeatures';
import React, { useEffect, useState } from 'react';
import VideoGif from '../components/AudiogramHome/VideoGif';
import WhatCreatorsSay from '@/components/AiVideoHome/WhatCreatorsSay';
import locale from '../locales/en/NativeLanding';
import transformation from '../assets/images/seo/transformation.png';
import transformationBg from '../assets/images/seo/transformation_background.svg';
import useSectionContentWidth from '../hooks/useSectionContentWidth';
const MAIN_VISUAL_URLS = {
  image:
    'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/new_index/2024/banner.png',
  video:
    'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/new_index/2024/banner.mp4'
};
export const title = locale.seo.title;
export const description = locale.seo.meta_desc;

const Index = (): JSX.Element => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    try {
      const accessToken = getItem(LocalStorageItems.ACCESS_TOKEN);
      if (!accessToken) return;
      setIsLoggedIn(accessToken && accessToken.length > 0);
    } catch (e) {
      console.error(e);
    }
  }, []);
  const { sectionWidthInPixels, sectionWidth } = useSectionContentWidth();

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        openGraph={{
          title: title,
          description: description,
          images: [
            {
              url: 'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/new_index/images/meta_image.jpg'
            }
          ]
        }}
        twitter={{
          handle: '@jupitrr_ai',
          site: '@jupitrr_ai',
          cardType: 'summary_large_image'
        }}
        additionalLinkTags={[
          {
            rel: 'icon',
            href: 'https://d3enyft5v5gx6r.cloudfront.net/website/SEO/favicon.png'
          }
        ]}
      />
      <div className="mb-8 flex w-full flex-col md:mb-0">
        <HeroBanner />
        <SectionContent maxWidth={sectionWidthInPixels}>
          <VideoGif
            videoSrc={MAIN_VISUAL_URLS.video}
            placeholderImageSrc={MAIN_VISUAL_URLS.image}
            className="h-[489px] w-full rounded-lg"
          />
        </SectionContent>
      </div>
      <FeaturesList />
      <Logos />
      <div
        className="mb-8 flex bg-cover bg-center md:mb-0"
        style={{ backgroundImage: `url(${transformationBg.src})` }}
      >
        <ArcadeDemo className="lg:h-[800px]" />
      </div>
      <FeaturesDetails />
      <WhatCreatorsSay />
      <MoreFeatures />
      <Examples />
      <FooterConversionCard />
      <Footer />
    </>
  );
};

const IndexPage = () => <Index />;
export default IndexPage;
