import { TextXL } from '../GeneralComponents';
import Image from 'next/image';

// import feature images
import Icon, { IconType } from '../Shared/Icon';
import autoBRoll_Feature from '../../assets/images/seo/auto_broll_feature.jpg';
import gifs_Feature from '../../assets/images/seo/gifs_feature.jpg';
import stockFootage_Feature from '../../assets/images/seo/stockfootage_feature.jpg';
import webImages_Feature from '../../assets/images/seo/webimage_feature.jpg';
const features = [
  {
    featureName: 'Stock Footage',
    featureIcon: IconType.StockFootageFeatureIcon,
    featureImage: stockFootage_Feature
  },
  {
    featureName: 'Web Images',
    featureIcon: IconType.SparklesIcon,
    featureImage: webImages_Feature
  },
  {
    featureName: 'GIFs',
    featureIcon: IconType.GIFFeatureIcon,
    featureImage: gifs_Feature
  },
  {
    featureName: 'Auto B-roll',
    featureIcon: IconType.SparklesIcon,
    featureImage: autoBRoll_Feature
  }
];

const FeaturesList = () => (
  <div className="m-auto my-8 grid w-auto max-w-[1088px] grid-cols-1  gap-4 px-3 sm:grid-cols-2 md:grid-cols-4">
    {features.map((feature, index) => (
      <div key={index} className="mx-auto my-4 flex flex-col lg:max-w-none">
        <div className="border-1 flex flex-col overflow-hidden rounded-lg border border-solid border-gray-200 bg-white shadow-sm">
          <div className="flex items-center justify-center gap-2">
            <Icon type={feature.featureIcon} />
            <h2 className="py-4 text-center text-lg font-medium leading-7">
              {feature.featureName}
            </h2>
          </div>
          <Image src={feature.featureImage} alt={feature.featureName} />
        </div>
      </div>
    ))}
  </div>
);

export default FeaturesList;
