import { Badge, NewJPButton, Text5XL } from '../GeneralComponents';
import { ScreenTypes, useScreenType } from '../../hooks/useScreenType';
import { SectionContent } from '../OldGeneralComponents';
import { useRouter } from 'next/router';
import Image from 'next/image';
import VideoGif from './VideoGif';
import locale from '../../locales/en/NativeLanding';
import parse from 'html-react-parser';
import useSectionContentWidth from '../../hooks/useSectionContentWidth';
const FeaturesDetails = () => {
  const { sectionWidthInPixels } = useSectionContentWidth();
  const router = useRouter();
  return (
    <SectionContent maxWidth={sectionWidthInPixels}>
      <Text5XL className="mb-10 mt-[80px] text-center md:mb-[80px] md:mt-[120px]">
        {locale.landing.features_summary_header}
      </Text5XL>
      <div className="relative flex w-full flex-col items-center justify-between">
        {locale.landing.features_summary.map((feature, index) => {
          return (
            <div
              key={feature.header}
              className={`mb-28 flex w-full flex-col items-center justify-between lg:flex-row ${
                index % 2 !== 0 ? 'lg:flex-row-reverse' : 'lg:flex-row'
              }`}
            >
              <div
                className={`mb-8 flex w-full items-center justify-center overflow-hidden rounded-3xl object-fill shadow-lg md:w-[350px] xl:mb-0 xl:h-[360px] xl:w-[479px]`}
              >
                {feature.video_url ? (
                  <VideoGif
                    width={479}
                    height={360}
                    videoSrc={feature.video_url}
                    placeholderImageSrc={feature.image_url}
                    className=""
                  />
                ) : (
                  <Image
                    src={feature.image_url}
                    width={479}
                    height={360}
                    alt={feature.header}
                    className="flex flex-1"
                  />
                )}
              </div>
              <div
                className={`flex w-full flex-col px-3 text-left md:text-center lg:max-w-[400px] lg:px-0 lg:text-left xl:max-w-[480px]`}
              >
                {!feature.isReady && (
                  <Badge
                    text={'Coming Soon'}
                    colorType="light-blue"
                    className="mx-auto mb-3 !w-[100px] !text-xs md:ml-0 md:mr-auto"
                  />
                )}
                <div className="mb-4 text-2xl font-700 lg:text-4xl xl:text-5xl">
                  {parse(feature.header)}
                </div>
                <div className="text-xl text-gray-500">{feature.subheader}</div>
              </div>
            </div>
          );
        })}
      </div>
    </SectionContent>
  );
};

export default FeaturesDetails;
